import fetchGql from '@/utils/fetchGql'
import InvestoreventsItems from '@/gql/getInvestoreventsItems.gql'
import InvestoreventsItem from '@/gql/getInvestoreventItem.gql'
import type { InvestoreventsItemsQueryVariables, InvestoreventsItemQueryVariables } from '~/gqlTypes/types'

export const useFetchInvestorEvent = () => {
  const fetchInvestorEvent = (language: string, slug?: string) => {
    const lang = language === 'en' ? '' : `${language}/`
    const variables: InvestoreventsItemQueryVariables = {
      id: `${lang}corporate/investors-airbaltic-com/events/${slug}`
    }

    return fetchGql(InvestoreventsItem, variables).then((response: any) => {
      return response.InvestoreventsItem
    })
  }

  return { fetchInvestorEvent }
}

export const useFetchPaginationEvent = () => {
  const fetchPaginationEvent = (language: string, date: string, isFutureEvent: boolean) => {
    const filterFuture = { date: { gt_date: date } }
    const filterPast = { date: { lt_date: date } }
    const variables: InvestoreventsItemsQueryVariables = {
      starts_with: language === 'en' ? '' : `${language}/*`,
      per_page: 2,
      filter: isFutureEvent ? filterFuture : filterPast,
      sortBy: isFutureEvent ? 'content.date:asc' : 'content.date:desc'
    }

    return fetchGql(InvestoreventsItems, variables).then((response: any) => response.InvestoreventsItems.items)
  }

  return { fetchPaginationEvent }
}
