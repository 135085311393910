<script lang="ts" setup>
  import { Drawer, EventDrawer, BaseButton, Text, ShareButton, Accordion } from '@bt/design-system'
  import { storeToRefs } from 'pinia'
  import { head } from 'lodash-es'
  import type { InvestorEvent } from '@/types/Storyblok/General/EventCard'
  import { useAppStore } from '@/stores'
  import { useFetchInvestorEvent, useFetchPaginationEvent } from '@/composables/useFetchInvestorEvent'

  const { fetchInvestorEvent } = useFetchInvestorEvent()
  const { fetchPaginationEvent } = useFetchPaginationEvent()

  const appStore = useAppStore()
  const { cmsPath, cmsPathParams, loading } = storeToRefs(appStore)
  const { locale, t } = useI18n()
  const { query, path } = useRoute()
  const { push: pushRouter } = useRouter()
  const isDrawerVisible = ref(false)
  const activeEvent = ref({ originalDate: '', slug: '' })
  const activeEventDrawerData = ref({ date: '', location: '', title: '', text: '' })
  const eventData = ref([])
  const isPreviousBtnDisabled = ref(false)
  const isNextBtnDisabled = ref(false)

  const props = withDefaults(
    defineProps<{
      blok: {
        [key: string]: InvestorEvent[]
      }
      showAccordion?: boolean
    }>(),
    {
      showAccordion: false
    }
  )

  onMounted(() => {
    if (query?.event) {
      openEvent(query.event.toString())
    }
  })

  const openEvent = async (slug: string) => {
    await fetchInvestorEvent(locale.value, slug).then((response) => singleInvestorEventFetchCallback([response]))
    await getInvestorEventData(activeEvent.value.originalDate, true, true)
    await getInvestorEventData(activeEvent.value.originalDate, false, true)
  }

  const getInvestorEventData = async (date: string, isFutureEvent: boolean, skipDataAssigment?: boolean) => {
    await fetchPaginationEvent(locale.value, date, isFutureEvent).then((re) =>
      skipDataAssigment
        ? checkIfStepButtonIsAvailable(re, isFutureEvent)
        : singleInvestorEventFetchCallback(re, isFutureEvent)
    )
  }

  const checkIfStepButtonIsAvailable = (re: any, isFutureEvent: boolean) => {
    const items = re.filter((item: any) => item.content.date)

    if (items.length < 1 && typeof isFutureEvent === 'boolean') {
      if (isFutureEvent) {
        isNextBtnDisabled.value = true
      } else {
        isPreviousBtnDisabled.value = true
      }
    }
  }

  const singleInvestorEventFetchCallback = (re: InvestorEvent[], isFutureEvent?: boolean) => {
    const areItemsValid = re.every((item: any) => item.content.date)

    if ((re.length < 2 || !areItemsValid) && typeof isFutureEvent === 'boolean') {
      if (isFutureEvent) {
        isNextBtnDisabled.value = true
      } else {
        isPreviousBtnDisabled.value = true
      }
    } else {
      isNextBtnDisabled.value = false
      isPreviousBtnDisabled.value = false
    }

    const data = head(re)
    if (!data?.content?.date) {
      loading.value = false

      return
    }

    activeEvent.value = {
      originalDate: data.content.date,
      slug: data.slug
    }

    activeEventDrawerData.value.date = data.content.date

    eventData.value = data.content.body
    isDrawerVisible.value = true

    pushRouter({
      path: t('investor-events.slug'),
      query: {
        event: data.slug
      }
    })
  }

  const loadEvent = (isFutureEvent: boolean) => {
    loading.value = true

    if (activeEvent.value.originalDate) {
      getInvestorEventData(activeEvent.value.originalDate, isFutureEvent, false)
    }

    document.querySelector('.drawer-container')?.scrollTo(0, 0)
  }

  const closeEvent = () => {
    isDrawerVisible.value = false
    pushRouter({
      path
    })
    if (cmsPathParams) {
      appStore.setCmsPath(path, {})
    }
  }

  const runtimeConfig = useRuntimeConfig()

  const fullInvestorEventUrl = computed(
    () => `${runtimeConfig.public.baseUrl}/${locale.value}/${cmsPath.value}?event=${activeEvent.value.slug}`
  )
  const eventTitle = computed(() => {
    if (!eventData.value.length) {
      return ''
    }

    const event = eventData.value[0] as Object

    if (typeof event === 'object' && 'text' in event) {
      return String(event.text)
    }
  })

  const getEventCardClass = (index: number, eventType: string, hasVideoRecording: boolean) => {
    if (!props.showAccordion && index === 0) {
      return 'event-card__type--first'
    }

    if (hasVideoRecording) {
      return 'event-card__type--video-recording'
    }

    if (eventType === 'event') {
      return 'event-card__type--event'
    }

    if (eventType === 'announcement') {
      return 'event-card__type--announcement'
    }

    return ''
  }
</script>
<template>
  <div class="cards-wrapper">
    <component
      :is="showAccordion ? Accordion : 'div'"
      v-for="(events, monthKey) in blok"
      :key="monthKey"
      :title="String(monthKey)"
      vertical-density="default"
      horizontal-density="default"
      title-type="h5"
      color="primary"
      :class="showAccordion ? 'events-cards__accordion' : 'events-cards__default'"
      content-density="none"
    >
      <div class="events-cards__wrapper">
        <div
          v-for="(card, index) in events"
          :key="card.content.date + card.content.eventType"
          class="event-card"
          :class="getEventCardClass(index, card.content?.eventType ?? '', card.content.hasVideoRecording)"
        >
          <div>
            <div class="event-card__chip">
              <span
                v-if="card.content.hasVideoRecording"
                class="flex gap-3 items-center"
              >
                {{ $t('investor-events.vide-recording-available') }}
                <img
                  alt="play arrow"
                  width="11"
                  height="14"
                  src="/play-arrow.svg"
                />
              </span>
              <template v-else>
                {{
                  card.content.eventType === 'event' ? $t('investor-events.event') : $t('investor-events.announcement')
                }}
              </template>
            </div>
            <div class="event-card__title">
              {{ card.content.cardText }}
            </div>
            <div class="event-card__description">
              {{ card.content.cardSubText }}
            </div>
          </div>
          <BaseButton
            color="ghost"
            :title="$t('buttons.read-more')"
            class="event-card__btn"
            @click="openEvent(card.slug)"
          />
        </div>
      </div>
    </component>
    <Drawer
      v-model="isDrawerVisible"
      rounded
      backdrop-color="light"
      side="right"
      @update:model-value="closeEvent"
    >
      <EventDrawer
        :data="activeEventDrawerData"
        @close-drawer="closeEvent"
      >
        <template #content>
          <template
            v-for="block in eventData"
            :key="block._uid"
          >
            <StoryblokComponent :blok="block" />
          </template>
        </template>
        <template #share>
          <div class="flex gap-4 items-center">
            <Text
              :text="`${$t('investor-events.share-event')}:`"
              type="subtitle1"
              class="mr-2"
            />
            <ShareButton :item="{ key: 'twitter', url: fullInvestorEventUrl, text: eventTitle }" />
            <ShareButton :item="{ key: 'facebook', url: fullInvestorEventUrl, text: eventTitle }" />
            <ShareButton :item="{ key: 'linkedin', url: fullInvestorEventUrl }" />
          </div>
        </template>
        <template #actions="{ className }">
          <BaseButton
            :class="className"
            color="ghost"
            :title="$t('investor-events.load-previous')"
            :disabled="isPreviousBtnDisabled"
            @click="loadEvent(false)"
          />
          <BaseButton
            :class="className"
            color="ghost"
            :title="$t('investor-events.load-next')"
            :disabled="isNextBtnDisabled"
            @click="loadEvent(true)"
          />
        </template>
      </EventDrawer>
    </Drawer>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/assets/styles/common.scss' as *;

  .events-cards {
    &__accordion {
      border-bottom: 1px solid $divider;

      &:last-child {
        border-top: 1px solid $divider;
      }

      .events-cards__wrapper {
        padding-bottom: rem(16);
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: rem(24);
    }
  }

  .event-card {
    width: 100%;
    border-radius: rem(8);
    padding: rem(24);
    display: flex;
    gap: rem(24);
    flex-direction: column;
    justify-content: space-between;

    @include desktop-up {
      flex-direction: row;
    }

    &__type {
      &--event {
        background-color: $light-green;

        .event-card__chip {
          border: 1px solid $dark-bt-green;
          color: $dark-bt-green;
        }
      }

      &--announcement,
      &--video-recording {
        background-color: $sky-blue;

        .event-card__chip {
          border: 1px solid $blue-ui-light;
          color: $info-dark;
        }
      }

      &--first {
        background: linear-gradient(96.38deg, $bt-blue 31.26%, $ui-dark-blue 98.26%);

        .event-card {
          &__chip {
            border: 1px solid $white;
            color: $white;
          }

          &__title,
          &__description,
          &__btn {
            color: $white;
          }
        }
      }
    }

    &__chip {
      padding: rem(2) rem(12);
      border-radius: 100px;
      width: fit-content;
      font-size: rem(14);
      margin-bottom: rem(16);
    }

    &__title {
      font-size: rem(18);
      font-weight: 500;
      line-height: rem(28);
      color: $bt-blue;
      margin-bottom: rem(16);

      @include desktop-up {
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(8);
      }
    }

    &__description {
      font-size: rem(14);
      color: $bt-blue;

      @include desktop-up {
        line-height: rem(24);
      }
    }

    &__btn {
      align-self: center;
      width: 100%;

      @include desktop-up {
        width: fit-content;
      }
    }
  }

  .cards-wrapper {
    :deep {
      .heading {
        margin-top: 0;
      }

      .drawer-container {
        > div:not(.media-banner) {
          padding-left: rem(16);
          padding-right: rem(16);

          @include desktop-up {
            padding-left: rem(48);
            padding-right: rem(48);
          }
        }
      }
    }
  }
</style>
